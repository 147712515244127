import React, { useState } from 'react';
import { Tabs, Tab, Button, Modal, Form } from 'react-bootstrap';
import RenderArticle from '../../components/Render/RenderArticle';
import TeamRegistrationForm from './TeamRegistrationForm';
import { Link } from 'react-router-dom';
import RankingsTable from './RankingsTable';

import api from '../../api/api';
import api_payment from '../../api/api_payment';

const Contest = (props) => {
    const { contest, team, user, is_payment_done } = props;
    const usr = JSON.parse(localStorage.getItem('user'));
    const [key, setKey] = useState('overview');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [rankings, setRankings] = useState([]);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [showTermsError, setShowTermsError] = useState(false);

    React.useEffect(() => {
        if (contest && contest.is_published && contest.result_published) {
            setLoading(true);
            api.get(`/contest/rank/${contest.id}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setRankings(response.data.ranks);
                    }
                    setLoading(false);
                }
                ).catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [contest]);

    // Payment button styles
    const paymentButtonStyles = {
        paid: {
            backgroundColor: '#308C84',
            color: 'white',
            border: 'none',
            padding: '8px 20px',
            borderRadius: '5px',
            cursor: 'not-allowed',
            opacity: '0.8',
            fontWeight: 'bold'
        },
        unpaid: {
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            padding: '8px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            fontWeight: 'bold',
            opacity: '0.8'
        }
    };

    // Modal styles
    const modalStyles = {
        header: {
            backgroundColor: '#f8f9fa',
            borderBottom: '1px solid #dee2e6',
            padding: '1rem'
        },
        title: {
            color: '#2c3e50',
            fontSize: '1.25rem',
            fontWeight: '600'
        },
        body: {
            padding: '1.5rem',
            color: '#666'
        },
        footer: {
            borderTop: '1px solid #dee2e6',
            padding: '1rem'
        },
        cancelButton: {
            backgroundColor: '#6c757d',
            border: 'none',
            marginRight: '10px',
            padding: '8px 20px'
        },
        confirmButton: {
            backgroundColor: 'red',
            border: 'none',
            padding: '8px 20px'
        },
        termsContainer: {
            marginTop: '20px',
            padding: '15px',
            backgroundColor: '#f8f9fa',
            borderRadius: '5px',
            border: '1px solid #dee2e6'
        },
        termsCheckbox: {
            marginBottom: '10px'
        },
        termsLinks: {
            color: 'red',
            textDecoration: 'underline',
            cursor: 'pointer'
        },
        termsError: {
            color: '#dc3545',
            fontSize: '0.875rem',
            marginTop: '5px'
        },
        policyLinks: {
            display: 'flex',
            gap: '15px',
            marginTop: '10px',
            fontSize: '0.875rem'
        }
    };

    // Payment processing function
    const processPayment = async () => {

        if (!contest || !team) {
            throw new Error('Invalid contest or team data');
        }
        if (!user) {
            throw new Error('User not logged in');
        }
        if (!contest.id || !team.id) {
            throw new Error('Invalid contest or team data');
        }

        const info = {
            contest_id: contest.id,
            team_id: team.id,
        };

        try {
            await api_payment.post('/contest/init_payment',
                info,
                {
                    headers: {
                        'Authorization': `Bearer ${usr.access_token}`,
                        "Content-type": "application/json",
                    },
                    withCredentials: true,
                }).then(res=>{
                    console.log(res);
                    if(res.status === 200){
                        window.location.replace(res.data.url);
                    }else{
                        throw new Error('Payment initiation failed');
                    }
                })
        } catch (error) {
            throw new Error('Payment initiation failed');
        }
    };


    // Error Modal Component
    const ErrorModal = () => (
        <Modal
            show={showErrorModal}
            onHide={() => setShowErrorModal(false)}
            centered
        >
            <Modal.Header style={modalStyles.header} closeButton>
                <Modal.Title style={{ ...modalStyles.title, color: '#dc3545' }}>
                    Payment Failed
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalStyles.body}>
                <p>{errorMessage || 'An error occurred during payment. Please try again.'}</p>
            </Modal.Body>
            <Modal.Footer style={modalStyles.footer}>
                <Button
                    variant="primary"
                    onClick={() => setShowErrorModal(false)}
                    style={{ ...modalStyles.confirmButton, backgroundColor: '#dc3545' }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    // Success Modal Component
    const SuccessModal = () => (
        <Modal
            show={showSuccessModal}
            onHide={() => setShowSuccessModal(false)}
            centered
        >
            <Modal.Header style={modalStyles.header} closeButton>
                <Modal.Title style={{ ...modalStyles.title, color: '#28a745' }}>
                    Payment Successful
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalStyles.body}>
                <p>Your payment of {contest?.fee} BDT has been processed successfully.</p>
                <p>A confirmation email has been sent to your registered email address.</p>
            </Modal.Body>
            <Modal.Footer style={modalStyles.footer}>
                <Button
                    variant="primary"
                    onClick={() => setShowSuccessModal(false)}
                    style={{ ...modalStyles.confirmButton, backgroundColor: '#28a745' }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    // Payment confirmation modal with terms
    const PaymentConfirmationModal = () => (
        <Modal
            show={showPaymentModal}
            onHide={() => {
                setShowPaymentModal(false);
                setTermsAgreed(false);
                setShowTermsError(false);
            }}
            centered
            size="lg"
        >
            <Modal.Header style={modalStyles.header} closeButton>
                <Modal.Title style={modalStyles.title}>
                    Confirm Payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalStyles.body}>
                <div>
                    <p>You are about to make a payment of <strong>{contest?.fee} BDT</strong> for:</p>
                    <ul style={{ paddingLeft: '20px' }}>
                        <li>Contest: <strong>{contest?.title}</strong></li>
                        <li>Team: <strong>{team?.team_name}</strong></li>
                        <li>Members: <strong>{team?.member_count}</strong></li>
                    </ul>
                </div>

                <div style={modalStyles.termsContainer}>
                    <Form.Group style={modalStyles.termsCheckbox}>
                        <Form.Check
                            type="checkbox"
                            id="terms-checkbox"
                            checked={termsAgreed}
                            onChange={(e) => {
                                setTermsAgreed(e.target.checked);
                                if (e.target.checked) {
                                    setShowTermsError(false);
                                }
                            }}
                            label={
                                <span style={{ fontSize: '0.9rem' }}>
                                    I HAVE READ AND AGREE TO THE WEBSITE'S
                                    <Link to="/terms-conditions" as="/terms-conditions"> TERMS AND CONDITIONS</Link>,
                                    <Link to="/privacy-policy" as="/privacy-policy"> PRIVACY POLICY</Link> AND
                                    <Link to="/refund-policy" as="/refund-policy"> REFUND POLICY</Link>
                                </span>
                            }
                        />
                        {showTermsError && (
                            <div style={modalStyles.termsError}>
                                You must agree to the terms and policies to proceed
                            </div>
                        )}
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer style={modalStyles.footer}>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setShowPaymentModal(false);
                        setTermsAgreed(false);
                        setShowTermsError(false);
                    }}
                    style={modalStyles.cancelButton}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={async () => {
                        if (!termsAgreed) {
                            setShowTermsError(true);
                            return;
                        }
                        setShowPaymentModal(false);
                        setIsProcessingPayment(true);

                        try {
                            await processPayment();
                        } catch (error) {
                            console.error('Payment failed:', error);
                            setErrorMessage(error.message);
                            setShowErrorModal(true);
                        } finally {
                            setIsProcessingPayment(false);
                            setTermsAgreed(false);
                        }
                    }}
                    style={modalStyles.confirmButton}
                    disabled={isProcessingPayment}
                >
                    {isProcessingPayment ? (
                        <>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Processing...
                        </>
                    ) : (
                        'Confirm Payment'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    // Modified payment button render function
    const renderPaymentButton = () => {
        if (is_payment_done === undefined) return null;

        if (is_payment_done) {
            return (
                <Button
                    style={paymentButtonStyles.paid}
                    disabled
                >
                    Payment Completed
                </Button>
            );
        }

        return (
            <>
                <Button
                    style={paymentButtonStyles.unpaid}
                    onClick={() => setShowPaymentModal(true)}
                    disabled={isProcessingPayment}
                >
                    Pay {contest?.fee} BDT
                </Button>
                <PaymentConfirmationModal />
            </>
        );
    };


    return (
        <>
            {/**Payment button */}
            {team && (
                <div style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px'
                }}>
                    {renderPaymentButton()}
                    {ErrorModal()}
                    {SuccessModal()}
                </div>
            )}

            <Tabs
                id="competition-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {/**Overview */}
                <Tab
                    eventKey="overview"
                    title={
                        <span style={{ color: key === 'overview' ? '#21a7d0' : 'black' }}>
                            Overview
                        </span>
                    }
                >
                    <div>
                        <div className="blog-desc">
                            {contest && <RenderArticle data={JSON.parse(contest?.description)} />}
                        </div>
                    </div>
                </Tab>

                {/**Leaderboard */}
                <Tab
                    eventKey="leaderboard"
                    title={
                        <span style={{ color: key === 'leaderboard' ? '#21a7d0' : 'black' }}>
                            Leaderboard
                        </span>
                    }
                >
                    <div>
                        {
                            contest && contest.is_published && contest.result_published && !loading &&
                            <div>
                                <RankingsTable rankings={rankings} />
                            </div>
                        }
                        {
                            contest && contest.is_published && contest.result_published && loading &&
                            <div>
                                Loading...
                            </div>
                        }
                        {
                            contest && (!contest.is_published || !contest.result_published) &&
                            <div>
                                <h3>To be announced</h3>
                            </div>
                        }
                    </div>
                </Tab>

                {/**Team */}
                {
                    user && team &&
                    <Tab
                        eventKey="team"
                        title={
                            <span style={{ color: key === 'team' ? '#21a7d0' : 'black' }}>
                                Team
                            </span>
                        }
                    >
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 mb-4">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <div className="card-title">
                                                <span
                                                    style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                                                >
                                                    {team.team_name}
                                                </span>
                                                <span
                                                    className="badge badge-primary ml-2"
                                                    style={{
                                                        fontSize: '1rem',
                                                        fontWeight: 'bold',
                                                        padding: '5px 10px',
                                                        borderRadius: '10px',
                                                        backgroundColor: team.approved ? '#28a745' : '#ffc107',
                                                        marginLeft: '10px'
                                                    }}
                                                >
                                                    {team.approved ? 'Approved' : 'Pending'}
                                                </span>
                                            </div>
                                            <ul>
                                                <li className="card-text"><strong>Email:</strong><a href={"mailto:" + team.email}> {team.email}</a></li>
                                                <li className="card-text"><strong>Phone:</strong> <a href={'tel:' + team.phone}>{team.phone}</a></li>
                                                <li className="card-text"><strong>Members:</strong> {team.member_count}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {team.members.map((member, index) => (
                                    <div key={index} className="col-md-4 col-sm-6 mb-4">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <h4 className="card-title">Member {index + 1}</h4>
                                                <ul>
                                                    <li className="card-text"><strong>Name:</strong> {member.name}</li>
                                                    <li className="card-text"><strong>Email:</strong><a href={"mailto:" + member.email}> {member.email}</a></li>
                                                    <li className="card-text"><strong>Institute:</strong> {member.institute}</li>
                                                    <li className="card-text"><strong>Session:</strong> {member.session}</li>
                                                    <li className="card-text"><strong>Student ID:</strong> {member.student_id}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Tab>
                }
                {
                    user && (!team) && (new Date(contest?.reg_deadline) > new Date()) &&
                    <Tab
                        eventKey="register"
                        title={
                            <span style={{ color: key === 'register' ? '#21a7d0' : 'black' }}>
                                Register
                            </span>
                        }
                    >
                        {/**Team Registration Form */}
                        <TeamRegistrationForm contest={contest} user={user} />
                    </Tab>
                }
                {
                    (!user) &&
                    <Tab
                        eventKey="register"
                        title={
                            <span style={{ color: key === 'register' ? '#21a7d0' : 'black' }}>
                                Register
                            </span>
                        }
                    >
                        <div>
                            Please <Link to="/login">Login</Link> to register for the contest.
                        </div>
                    </Tab>
                }
            </Tabs>
        </>

    );
}

export default Contest;

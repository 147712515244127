import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CourseMain from './CourseMain';

// Image
import logo from '../../assets/img/logo/csai.png';

import bannerbg from '../../assets/img/breadcrumbs/breadcrumbs.jpg';

const TutorialTopics = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='course'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='contact@vasha.com.bd'
                Location='Science Complex Building, University of Dhaka, Dhaka 1000'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Course Categories"
                innerClass="breadcrumbs-text"
                pageName="Tutorial Topics"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-start */}

            {/* Course Main */}
            <CourseMain />
            {/* Course Main */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            /> */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default TutorialTopics;
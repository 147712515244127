import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import Footer from '../../../components/Layout/Footer/Footer';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import Newsletter from '../../../components/Common/Newsletter';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import CourseDetailsMain from './CourseDetailsMain';

// Image
import logo from '../../../assets/img/logo/csai.png'
import bannerbg from '../../../assets/img/breadcrumbs/2.jpg';


import api from '../../../api/api';
import apis from '../../../api/apis';

const Tutorial = () => {

    let search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const [tutorial, setTutorial] = React.useState(null);
    const [topic, setTopic] = React.useState(null);

    React.useEffect(() => {
        api.get('/tutorial?id=' + id)
            .then(res => {
                setTutorial(res.data);
                if (!topic) {
                    fetchTopic(res.data.topic_id);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [id]);

    const fetchTopic = async (topic_id) => {
        try {
            await api.get('/tutorial/topic?id=' + topic_id)
                .then(res => {
                    // console.log('topic', res.data);
                    setTopic([res.data]);
                })
                .catch(err => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='course'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='contact@vasha.com.bd'
                Location='Science Complex, University of Dhaka, Dhaka, Bangladesh'
            />

            {/* breadcrumb-area-start */}
            {/* <SiteBreadcrumb
                pageTitle={topic?.name}
                pageName={"Course Details"}
                breadcrumbsImg={bannerbg}
            /> */}
            {/* breadcrumb-area-start */}

            {/* Course Details Main */}
            {
                tutorial ?
                    <CourseDetailsMain tutorial={tutorial} topic={topic} /> :
                    <div class="text-center">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
            }
            {/* Course Details Main */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 gray-bg orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            /> */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default Tutorial;
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

export default axios.create({
    // baseURL: 'http://localhost:8003',
    // baseURL: 'http://192.168.0.106:8060',
    // baseURL: 'https://pay-test.vasha.com.bd',
    // baseURL: 'https://pay.vasha.com.bd',
    // baseURL: 'http://103.157.135.249:8000',
     baseURL: 'https://pay.cognistorm.ai'
});

import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Home from '../pages/home'; // Landing Page

// Support Static Pages
import Contact from '../pages/contact';
import About from '../pages/about';
import TermsConditions from '../pages/terms-conditions';
import PrivacyPolicy from '../pages/privacy-policy';
import RefundPolicy from '../pages/refund-policy';



import Login from '../pages/login';
import MyAccount from '../pages/shop/my-account';

// ComingSoon as placeholder for future pages
import ComingSoon from '../pages/comming-soon';

// Applications
import Colas from '../pages/colas'; // Colas App

// Blog
import Blog from '../pages/blog';
import BlogRight from '../pages/blog/BlogRight';
import SinglePostFullWidth from '../pages/blog/single-post-full-width';
import BlogWritePage from '../pages/write';

// Tutorial
import TutorialTopics from '../pages/tutorial-topics';
import TutorialTopic from '../pages/tutorial-one';
import Tutorial from '../pages/tutorial-one/course-single';

import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import Competition from '../pages/competition';
import SingleContest from '../pages/competition/SingleContest';

//Contributions
import Contribute from '../pages/contribute'

//Static Pages that should be dynamic in the future
import Workshop from '../pages/workshps';


import { v4 as uuidv4 } from 'uuid';
import auth_api from '../api/auth_api';

const App = () => {
    const cookies = new Cookies(null, { path: '/' });

    React.useEffect(async () => {
        if (!localStorage.getItem('userId')) {
            const uuid = uuidv4();
            anonym(uuid);
        }
        else if (!sessionStorage.getItem('userId')) {
            anonym(localStorage.getItem('userId'));
        }
        if (!cookies.get('access_token') && localStorage.getItem('user')) {
            localStorage.removeItem('user');
            window.location.reload();
        }
    }, [cookies]);

    const anonym = async (uuid) => {
        const user = localStorage.getItem('user');
        if (!user) {
            await auth_api.post('/anonym', {
                uuid: uuid
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                })
                .then(response => {
                    localStorage.setItem('userId', response.data['userId']);
                    sessionStorage.setItem('userId', response.data['userId']);
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            await auth_api.post('/anonym', {
                uuid: uuid,
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(user).access_token
                    },
                    withCredentials: true
                })
                .then(response => {
                    localStorage.setItem('userId', response.data['userId']);
                    sessionStorage.setItem('userId', response.data['userId']);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>

                    /{/**Home */}
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />

                    {/**Products */}
                    <Route path="/summarizer" component={ComingSoon} />
                    <Route path="/colas" component={Colas} />

                    {/**Tutorial */}
                    <Route path="/course-categories" component={TutorialTopics} /> {/*List of Topics*/}
                    <Route path="/tutorial" component={TutorialTopic} /> {/*List of Tutorials of a specific Topic*/}
                    <Route path="/course/course-single" component={Tutorial} /> {/*Single Tutorial*/}

                    {/**Blog */}
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/single-post-full-width" component={SinglePostFullWidth} />
                    <Route path="/blog/blog-right-sidebar" component={BlogRight} />
                    <Route path="/write" component={BlogWritePage} />


                    {/**Competition */}
                    <Route path="/competition" component={Competition} />
                    <Route path="/contest" component={SingleContest} />

                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-conditions" component={TermsConditions} />
                    <Route path="/refund-policy" component={RefundPolicy} />

                    {/**Authentication */}
                    <Route path="/login" component={Login} />
                    <Route path="/account" component={MyAccount} />

                    {/**Contribute */}
                    <Route path="/contribute" component={Contribute} />

                    {/** Workshop */}
                    <Route path="/llm-workshop" component={Workshop} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
// 
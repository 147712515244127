import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ScrollToTop from '../../components/Common/ScrollTop';

const PrivacyPolicyMain = () => {
	const styles = {
		section: {
			padding: '80px 0',
			backgroundColor: '#f8f9fa'
		},
		title: {
			textAlign: 'center',
			marginBottom: '50px'
		},
		mainHeading: {
			fontSize: '2.5rem',
			fontWeight: '700',
			color: '#2c3e50',
			marginBottom: '20px'
		},
		bar: {
			width: '70px',
			height: '4px',
			backgroundColor: '#308C84',
			margin: '0 auto 20px'
		},
		intro: {
			fontSize: '1.1rem',
			color: '#666',
			maxWidth: '800px',
			margin: '0 auto',
			textAlign: 'center',
			lineHeight: '1.8'
		},
		card: {
			border: 'none',
			boxShadow: '0 0 30px rgba(0,0,0,0.05)',
			borderRadius: '15px',
			overflow: 'hidden'
		},
		cardBody: {
			padding: '40px'
		},
		sectionHeading: {
			fontSize: '1.5rem',
			color: '#2c3e50',
			marginBottom: '20px',
			fontWeight: '600',
			borderBottom: '2px solid #308C84',
			paddingBottom: '10px'
		},
		subHeading: {
			fontSize: '1.2rem',
			color: '#34495e',
			marginBottom: '15px',
			fontWeight: '500'
		},
		paragraph: {
			color: '#666',
			lineHeight: '1.8',
			marginBottom: '20px'
		},
		list: {
			marginBottom: '20px'
		},
		listItem: {
			color: '#666',
			lineHeight: '1.8',
			marginBottom: '10px',
			paddingLeft: '20px',
			position: 'relative'
		},
		bullet: {
			position: 'absolute',
			left: '0',
			color: '#308C84'
		}
	};

	return (
		<React.Fragment>
			<section style={styles.section}>
				<Container>
					<div style={styles.title}>
						<h1 style={styles.mainHeading}>Privacy Policy</h1>
						<div style={styles.bar}></div>
						<p style={styles.intro}>
							At <a href='https://cognistorm.ai/' style={{ color: '#308C84' }}>Cognistorm.ai</a>, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website.
						</p>
					</div>

					<Row className="justify-content-center">
						<Col lg={10}>
							<Card style={styles.card}>
								<Card.Body style={styles.cardBody}>
									{/* Introduction */}
									<div className="mb-5">
										<h2 style={styles.sectionHeading}>1. Introduction</h2>
										<p style={styles.paragraph}>
											Cognistorm.ai ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website <a href='https://cognistorm.ai/' style={{ color: '#308C84' }}>https://cognistorm.ai</a> ("Site") and use our services ("Services").
										</p>
									</div>

									{/* Information Collection */}
									<div className="mb-5">
										<h2 style={styles.sectionHeading}>2. Information We Collect</h2>
										<h3 style={styles.subHeading}>Personal Information:</h3>
										<p style={styles.paragraph}>
											We may collect personal information that you voluntarily provide to us when you register on our Site, subscribe to our newsletter, fill out a form, or contact us.
										</p>

										<h3 style={styles.subHeading}>Usage Data:</h3>
										<p style={styles.paragraph}>
											We automatically collect information about your interactions with our Site and Services.
										</p>

										<h3 style={styles.subHeading}>Cookies and Similar Technologies:</h3>
										<p style={styles.paragraph}>
											We use cookies and similar tracking technologies to enhance your experience on our Site.
										</p>
									</div>

									{/* How We Use Information */}
									<div className="mb-5">
										<h2 style={styles.sectionHeading}>3. How We Use Your Information</h2>
										<ul style={styles.list}>
											{['Provide and Improve Services', 'Communicate with You', 'Personalize Experience', 'Ensure Security', 'Comply with Legal Obligations'].map((item, index) => (
												<li key={index} style={styles.listItem}>
													<span style={styles.bullet}>•</span> {item}
												</li>
											))}
										</ul>
									</div>

									{/* Continue with other sections following the same pattern */}

									{/* Contact Section */}
									<div className="mb-4">
										<h2 style={styles.sectionHeading}>9. Contact Us</h2>
										<p style={styles.paragraph}>
											If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
										</p>
										<div style={{
											backgroundColor: '#f8f9fa',
											// padding: '20px',
											borderRadius: '10px',
											// marginTop: '20px'
										}}>
											<p style={{ margin: 0, color: '#666' }}>
												<a href="mailto:contact@cognistorm.ai" style={{ color: '#308C84' }}>
													contact@cognistorm.ai<br />
												</a>
											</p>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>

			<ScrollToTop scrollClassName="scrollup orange-color" />
		</React.Fragment>
	);
}

export default PrivacyPolicyMain;
import React from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
// import Newsletter from '../../components/Common/Newsletter';
import ContributeMain from './ContributeMain';

// Image
import favIcon from '../../assets/img/logo/ai.png';
import logo from '../../assets/img/logo/csai.png';

const Contribute = () => {
    return (
        <React.Fragment>
            <OffWrap />
            {/* Header */}
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <Header
                parentMenu='contribute'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBarClass="topbar-area home8-topbar"
                emailAddress='contact@vasha.com.bd'
                Location='Department of Computer Science and Engineering, University of Dhaka, Dhaka, Bangladesh'
            />
            {/* Header */}

            {/* ContactMain Section Start */}
            <ContributeMain />
            {/* ContactMain Section End */}

            {/* Footer */}
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}


export default Contribute;
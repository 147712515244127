import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-6 md-mb-10">
                        <div className="copyright md-mb-0">
                        <p>&copy; 
                            {new Date().getFullYear()}  All Rights Reserved by CogniStormAI@CAIL, CSEDU
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end md-text-left">
                        <ul className="copy-right-menu">
                            <li><Link to="/privacy-policy" as="/privacy-policy">Privacy & Policy</Link></li>
                            <li><Link to="/terms-conditions" as="/terms-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/refund-policy" as="/refund-policy">Return and Refund Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api_payment from '../../api/api_payment';

const ContributeMain = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    amount: '',
    currency: 'BDT',
    agreeToTerms: false,
  });

  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    contact: '',
    amount: '',
    terms: ''
  });

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return !value.trim() ? 'Name is required' : '';
      case 'email':
        return !value.trim()
          ? 'Email is required'
          : !/\S+@\S+\.\S+/.test(value)
            ? 'Invalid email format'
            : '';
      case 'contact':
        return !value.trim() ? 'Contact number is required' : '';
      case 'amount':
        return !value || value < 10 ? 'Please enter at least 10 BDT' : '';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    setErrors(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? '' : validateField(name, value)
    }));
  };

  const handleBlur = (e) => {
    const { name, value, type } = e.target;
    if (type !== 'checkbox') {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, value)
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      name: validateField('name', formData.name),
      email: validateField('email', formData.email),
      contact: validateField('contact', formData.contact),
      amount: validateField('amount', formData.amount),
      terms: !formData.agreeToTerms ? 'Please agree to the terms and conditions' : ''
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    setShowConfirmationModal(true);
  };

  const handlePaymentConfirmation = async () => {
    setLoading(true);
    try {
      const response = await api_payment.post('/donation/init_payment', {
        name: formData.name,
        email: formData.email,
        contact: formData.contact,
        amount: formData.amount,
        currency: formData.currency,
      });

      if (response.status === 200 && response.data.url) {
        window.location.replace(response.data.url);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Payment initialization failed:', error);
      setShowConfirmationModal(false);
      setShowFailureModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowConfirmationModal(false);
    setShowFailureModal(false);
  };

  return (
    <div className="contribution-page">
      <div className="content-wrapper">
        <div className="header-section">
          <div className="icon-container">
            {/* <span className="heart-icon">💝</span> */}
          </div>
          <h1>Contribute to CSAI</h1>
          <p>Your contribution helps us create positive change and supports our mission to build better AI solutions</p>
        </div>

        <div className="form-card">
          <form onSubmit={handleFormSubmit} className="contribution-form">
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your full name"
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <span className="error-text">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your email address"
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <span className="error-text">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="contact">Contact Number</label>
              <input
                id="contact"
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your contact number"
                className={errors.contact ? 'error' : ''}
              />
              {errors.contact && <span className="error-text">{errors.contact}</span>}
            </div>

            <div className="amount-section">
              <div className="form-group">
                <label htmlFor="amount">Contribution Amount</label>
                <div className="amount-input-group">
                  <input
                    id="amount"
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter amount"
                    className={errors.amount ? 'error' : ''}
                  />
                  <select
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                  >
                    <option value="BDT">BDT</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </select>
                </div>
                {errors.amount && <span className="error-text">{errors.amount}</span>}
              </div>
            </div>

            <div className="terms-section">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  name="agreeToTerms"
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                />
                <span className="checkmark"></span>
                <div className="terms-text">
                  I have read and agree to the website's
                  <Link to="/terms-conditions"> Terms and Conditions</Link>,
                  <Link to="/privacy-policy"> Privacy Policy</Link> and
                  <Link to="/refund-policy"> Refund Policy</Link>
                </div>
              </label>
              {errors.terms && <span className="error-text terms-error">{errors.terms}</span>}
            </div>

            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Processing...' : 'Proceed to Payment'}
              {!loading && <span className="arrow">→</span>}
            </button>
          </form>
        </div>

        <div className="trust-badges">
          <div className="badge">
            <span className="badge-icon">🔒</span>
            <span>Secure Payment</span>
          </div>
          <div className="badge">
            <span className="badge-icon">⚡</span>
            <span>Instant Processing</span>
          </div>
          <div className="badge">
            <span className="badge-icon">✨</span>
            <span>100% Transparent</span>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-header">
              <h2>Confirm Your Contribution</h2>
              <button className="close-button" onClick={handleCloseModal}>&times;</button>
            </div>
            <div className="modal-body">
              <div className="confirmation-details">
                <div className="detail-item">
                  <span>Amount:</span>
                  <strong>{formData.amount} {formData.currency}</strong>
                </div>
                <div className="detail-item">
                  <span>Name:</span>
                  <strong>{formData.name}</strong>
                </div>
                <div className="detail-item">
                  <span>Email:</span>
                  <strong>{formData.email}</strong>
                </div>
                <div className="detail-item">
                  <span>Contact:</span>
                  <strong>{formData.contact}</strong>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn-primary"
                onClick={handlePaymentConfirmation}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Confirm Payment'}
              </button>
              <button
                className="btn-secondary"
                onClick={handleCloseModal}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showFailureModal && (
        <div className="modal-overlay">
          <div className="modal-container error">
            <div className="modal-header">
              <h2>Payment Failed</h2>
              <button className="close-button" onClick={handleCloseModal}>&times;</button>
            </div>
            <div className="modal-body">
              <p>Unfortunately, we couldn't process your payment. Please try again later.</p>
            </div>
            <div className="modal-footer">
              <button className="btn-primary" onClick={handleCloseModal}>Close</button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .contribution-page {
          min-height: 100vh;
          background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
          padding: 40px 20px;
        }

        .content-wrapper {
          max-width: 800px;
          margin: 0 auto;
        }

        .header-section {
          text-align: center;
          margin-bottom: 40px;
        }

        .header-section h1 {
          color: #308C84;
          font-size: 2.5rem;
          margin-bottom: 15px;
          font-weight: 600;
        }

        .header-section p {
          color: #4a5568;
          font-size: 1.1rem;
          max-width: 600px;
          margin: 0 auto;
          line-height: 1.6;
        }

        .heart-icon {
          font-size: 3.5rem;
          animation: pulse 2s infinite;
          display: inline-block;
          margin-bottom: 20px;
        }

        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.1); }
          100% { transform: scale(1); }
        }

        .form-card {
          background: white;
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          padding: 40px;
          margin-bottom: 30px;
        }

        .form-group {
          margin-bottom: 24px;
          position: relative;
        }

        .form-group label {
          display: block;
          margin-bottom: 8px;
          color: #2d3748;
          font-weight: 500;
          font-size: 0.95rem;
        }

        input, select {
          width: 100%;
          padding: 12px 16px;
          border: 1.5px solid #e2e8f0;
          border-radius: 8px;
          font-size: 1rem;
          transition: all 0.3s ease;
          color: #2d3748;
          background-color: #fff;
        }

        input::placeholder {
          color: #a0aec0;
        }

        input:focus, select:focus {
          border-color: #308C84;
          box-shadow: 0 0 0 3px rgba(48, 140, 132, 0.1);
          outline: none;
        }

        input.error, select.error {
          border-color: #dc3545;
          background-color: #fff8f8;
        }

        input.error:focus, select.error:focus {
          border-color: #dc3545;
          box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
        }

        .error-text {
          color: #dc3545;
          font-size: 0.85rem;
          margin-top: 4px;
          display: block;
        }

        .amount-input-group {
          display: flex;
          gap: 12px;
        }

        .amount-input-group select {
          width: 120px;
          flex-shrink: 0;
        }

        .terms-section {
          margin: 24px 0;
        }

        .checkbox-container {
          display: flex;
          align-items: flex-start;
          gap: 12px;
          cursor: pointer;
        }

        .terms-text {
          font-size: 0.9rem;
          line-height: 1.5;
          color: #4a5568;
          flex: 1;
        }

        .terms-text a {
          color: #308C84;
          text-decoration: none;
          font-weight: 500;
          transition: color 0.3s ease;
        }

        .terms-text a:hover {
          color: #246b65;
          text-decoration: underline;
        }

        .terms-error {
          margin-top: 8px;
          padding-left: 32px;
        }

        .submit-button {
          width: 100%;
          padding: 16px;
          background: #308C84;
          color: white;
          border: none;
          border-radius: 8px;
          font-size: 1.1rem;
          font-weight: 600;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          transition: all 0.3s ease;
          margin-top: 20px;
        }

        .submit-button:hover:not(:disabled) {
          background: #246b65;
          transform: translateY(-2px);
        }

        .submit-button:disabled {
          background: #94b8b4;
          cursor: not-allowed;
          transform: none;
        }

        .arrow {
          transition: transform 0.3s ease;
        }

        .submit-button:hover:not(:disabled) .arrow {
          transform: translateX(5px);
        }

        .trust-badges {
          display: flex;
          justify-content: center;
          gap: 40px;
          margin-top: 30px;
        }

        .badge {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #4a5568;
          font-size: 0.9rem;
        }

        .badge-icon {
          font-size: 1.4rem;
        }

        /* Custom checkbox styles */
        .checkbox-container input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 2px solid #308C84;
          border-radius: 4px;
          display: inline-block;
          flex-shrink: 0;
          position: relative;
          transition: all 0.3s ease;
        }

        .checkbox-container input[type="checkbox"]:checked ~ .checkmark {
          background-color: #308C84;
        }

        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 2px;
          width: 4px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        .checkbox-container input[type="checkbox"]:checked ~ .checkmark:after {
          display: block;
        }

        /* Modal Styles */
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          padding: 20px;
        }

        .modal-container {
          background: white;
          border-radius: 12px;
          width: 100%;
          max-width: 500px;
          padding: 24px;
          position: relative;
        }

        .modal-container.error {
          border-top: 4px solid #dc3545;
        }

        .modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }

        .modal-header h2 {
          color: #308C84;
          margin: 0;
          font-size: 1.5rem;
          font-weight: 600;
        }

        .close-button {
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
          color: #666;
          padding: 0;
          line-height: 1;
        }

        .confirmation-details {
          margin: 20px 0;
        }

        .detail-item {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          border-bottom: 1px solid #e2e8f0;
        }

        .detail-item:last-child {
          border-bottom: none;
        }

        .modal-footer {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
          margin-top: 24px;
        }

        .btn-primary, .btn-secondary {
          padding: 10px 20px;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .btn-primary {
          background: #308C84;
          color: white;
          border: none;
        }

        .btn-primary:hover:not(:disabled) {
          background: #246b65;
        }

        .btn-secondary {
          background: #e2e8f0;
          color: #4a5568;
          border: none;
        }

        .btn-secondary:hover:not(:disabled) {
          background: #cbd5e0;
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .form-card {
            padding: 24px;
          }

          .header-section h1 {
            font-size: 2rem;
          }

          .trust-badges {
            flex-direction: column;
            align-items: center;
            gap: 20px;
          }
        }

        @media (max-width: 480px) {
          .header-section h1 {
            font-size: 1.8rem;
          }

          .amount-input-group {
            flex-direction: column;
          }

          .amount-input-group select {
            width: 100%;
          }

          .terms-text {
            font-size: 0.85rem;
          }

          .badge {
            font-size: 0.85rem;
          }

          .modal-container {
            padding: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default ContributeMain;

import React from 'react';
import { Link } from 'react-router-dom';

const Contribution = () => {
    return (
        <div className="contribution-container">
            <div className="content-wrapper">
                <div className="header-section">
                    <div className="icon-container">
                        {/* <span className="heart-icon">💝</span> */}
                    </div>
                    <h1>Make a Difference Today</h1>
                    <p>Your contribution helps us create positive change and supports our mission to build better AI solutions.</p>
                </div>

                <div className="contribution-card">
                    <div className="card-content">
                        <div className="impact-points">
                            <div className="impact-item">
                                <span className="impact-icon">🎯</span>
                                <p>Direct impact on AI development</p>
                            </div>
                            <div className="impact-item">
                                <span className="impact-icon">🌟</span>
                                <p>Support innovative research</p>
                            </div>
                            <div className="impact-item">
                                <span className="impact-icon">🤝</span>
                                <p>Join a community of contributors</p>
                            </div>
                        </div>

                        <Link to="/contribute" className="contribute-button">
                            <span>Contribute Now</span>
                            <span className="arrow">→</span>
                        </Link>
                    </div>
                </div>

                <div className="trust-section">
                    <div className="trust-item">
                        <span className="trust-icon">🔒</span>
                        <p>Secure Payments</p>
                    </div>
                    <div className="trust-item">
                        <span className="trust-icon">📊</span>
                        <p>Transparent Impact</p>
                    </div>
                    <div className="trust-item">
                        <span className="trust-icon">💯</span>
                        <p>100% to R&D</p>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .contribution-container {
                    min-height: 100vh;
                    background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
                    padding: 40px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .content-wrapper {
                    max-width: 800px;
                    width: 100%;
                }

                .header-section {
                    text-align: center;
                    margin-bottom: 40px;
                }

                .icon-container {
                    margin-bottom: 20px;
                }

                .heart-icon {
                    font-size: 3.5rem;
                    animation: pulse 2s infinite;
                }

                @keyframes pulse {
                    0% { transform: scale(1); }
                    50% { transform: scale(1.1); }
                    100% { transform: scale(1); }
                }

                .header-section h1 {
                    font-size: 2.5rem;
                    color: #2d3748;
                    margin-bottom: 15px;
                    font-weight: 700;
                }

                .header-section p {
                    font-size: 1.1rem;
                    color: #4a5568;
                    max-width: 600px;
                    margin: 0 auto;
                    line-height: 1.6;
                }

                .contribution-card {
                    background: white;
                    border-radius: 20px;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
                    padding: 40px;
                    margin-bottom: 40px;
                    transition: transform 0.3s ease;
                }

                .contribution-card:hover {
                    transform: translateY(-5px);
                }

                .impact-points {
                    display: grid;
                    gap: 20px;
                    margin-bottom: 30px;
                }

                .impact-item {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                }

                .impact-icon {
                    font-size: 1.5rem;
                }

                .impact-item p {
                    margin: 0;
                    color: #4a5568;
                    font-size: 1.1rem;
                }

                .contribute-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: linear-gradient(135deg, #308C84 0%,rgb(14, 74, 69) 100%);
                    color: white;
                    padding: 15px 30px;
                    border-radius: 30px;
                    text-decoration: none;
                    font-size: 1.1rem;
                    font-weight: 600;
                    transition: all 0.3s ease;
                }

                .contribute-button:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
                    color: white;
                }

                .arrow {
                    transition: transform 0.3s ease;
                }

                .contribute-button:hover .arrow {
                    transform: translateX(5px);
                }

                .trust-section {
                    display: flex;
                    justify-content: center;
                    gap: 40px;
                    flex-wrap: wrap;
                    text-align: center;
                }

                .trust-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                }

                .trust-icon {
                    font-size: 1.5rem;
                }

                .trust-item p {
                    margin: 0;
                    color: #4a5568;
                    font-size: 0.9rem;
                }

                @media (max-width: 768px) {
                    .header-section h1 {
                        font-size: 2rem;
                    }

                    .contribution-card {
                        padding: 30px;
                    }

                    .trust-section {
                        gap: 20px;
                    }

                    .impact-points {
                        gap: 15px;
                    }
                }

                @media (max-width: 480px) {
                    .header-section h1 {
                        font-size: 1.8rem;
                    }

                    .contribution-card {
                        padding: 20px;
                    }

                    .impact-item p {
                        font-size: 1rem;
                    }
                }
            `}</style>
        </div>
    );
}

export default Contribution;
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import api from '../../../api/api';

const RSMobileMenu = ({ setMenuOpen, menuOpen, parentMenu, secondParentMenu, headerFullWidth }) => {

	const location = useLocation();

	const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [tutorial, setTutorial] = useState(false)
	const [pages, setPages] = useState(false)
	const [team, setTeam] = useState(false)
	const [event, setEvent] = useState(false) //solutions
	const [gallery, setGallery] = useState(false)
	const [shop, setShop] = useState(false)
	const [otherPages, setOtherPages] = useState(false)
	const [blog, setBlog] = useState(false)
	const [blogSidebar, setBlogSidebar] = useState(false)
	const [blogSingle, setBlogSingle] = useState(false)
	const [contact, setContact] = useState(false)
	const [competition, setCompetition] = useState(false)
	const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
	const [topics, setTopics] = React.useState([]);
	const [contribute, setContribute] = React.useState(false);

	React.useEffect(() => {
		api.get('/tutorial/topic')
			.then((response) => {
				setTopics(response.data);
			}).catch((error) => {
				console.error('Error fetching data: ', error);
			});

	}, []);

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'tutorial') {
			setHome(false)
			setAbout(false)
			setTutorial(!tutorial)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(!pages)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'team') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(true)
			setTeam(!team)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'solutions') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(true)
			setTeam(false)
			setEvent(!event)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'gallery') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(!gallery)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'shop') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(!shop)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'otherPages') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(!otherPages)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(!blog)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blogSidebar') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(true)
			setBlogSidebar(!blogSidebar)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blogSingle') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(true)
			setBlogSidebar(false)
			setBlogSingle(!blogSingle)
			setContact(false)
		}
		else if (menu === 'contact') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(!contact)
		}
		else if (menu === 'competition') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
			setCompetition(!competition)
		}
		else if (menu === 'contribute') {
			setHome(false)
			setAbout(false)
			setTutorial(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
			setCompetition(false)
			setContribute(!contribute)
		}
	};
	// console.log("parentMenu: ", parentMenu)
	return (
		<div className={headerFullWidth ? "container-fluid relative" : "container relative"}>
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li className={home ? " current-menu-item" : ""}>
							<Link to="/" onClick={() => { openMobileMenu('home'); }} className={parentMenu === 'home' ? 'active-menu' : ''}>Home</Link>
						</li>

						{/**Solutions */}
						<li className={event ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('solutions'); }} className={parentMenu === 'solutions' ? 'active-menu' : ''}>Solutions</Link>
							<ul className={event ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to={{ pathname: "https://ocr.vasha.com.bd/" }} target="_blank" className={secondParentMenu === 'team' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>OCR</Link>
								</li>
								<li>
									<Link to="/colas" className={secondParentMenu === 'event' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>COLAS</Link>
								</li>
								<li>
									<Link to="/home-9" className={secondParentMenu === 'event' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Summarizer</Link>
								</li>
							</ul>
						</li>

						{/**Tutorial*/}
						<li className={tutorial ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('tutorial'); }} className={parentMenu === 'tutorial' ? 'active-menu' : ''}>tutorial</Link>
							<ul className={tutorial ? "sub-menu current-menu" : "sub-menu"}>
								{
									topics && topics.map((topic, index) => (
										<li key={index}>
											<Link to={`/tutorial?id=${topic.id}`} className={location.pathname === `/tutorial/${topic.id}` ? "active-menu" : ""}>{topic.name}</Link>
										</li>
									))
								}
							</ul>
						</li>

						{/**Blog*/}
						<li className={blog ? " current-menu-item" : ""}>
							<Link to="/blog" onClick={() => { openMobileMenu('blog'); }} className={parentMenu === 'blog' ? 'active-menu' : ''}>Blog</Link>
						</li>
						<li className=''>
							<Link to="/competition" onClick={() => { openMobileMenu('competition'); }} className={parentMenu === 'competition' ? 'active-menu' : ''}>Competition</Link>
						</li>

						{/**About */}
						{/* <li className={about ? " current-menu-item" : ""}>
							<Link to="/about" onClick={() => { openMobileMenu('about'); }} className={parentMenu === 'about' ? 'active-menu' : ''}>About</Link>
						</li> */}

						{/**Contact*/}
						<li className={contact ? " current-menu-item" : ""}>
							<Link to="/contact" onClick={() => { openMobileMenu('contact'); }} className={parentMenu === 'contact' ? 'active-menu' : ''}>Contact</Link>
						</li>

						{/** Write */}
						<li className={parentMenu === 'write' ? 'current-menu-item' : ''}>
							<Link to={user ? "/write" : "/login"}>
								Write
								<i className="fa fa-pencil-square-o fa-sm fa-black p-1" style={{ color: "gray" }}></i>
							</Link>
						</li>

						{/**Login or User Profile */}
						<li className={parentMenu === 'write' ? 'current-menu-item' : ''}>
							{
								user ?
									<Link to="/account">
										<span className='m-1'>Account</span>
										<img
											src={user.picture}
											alt="User Profile"
											className="rounded-circle"
											style={{ width: '20px', height: '20px', objectFit: 'cover' }}  // Adjust size as needed
										/>
									</Link>
									:
									<Link to="/login">
										<span className='m-2'>Login</span>
										<i className="fa fa-sign-in fa-lg" aria-hidden="true"></i>
									</Link>
							}
						</li>

					</ul>

				</div>

			</div>
		</div>
	)
}

export default RSMobileMenu;
import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import logo from '../../assets/img/logo/csai.png';
import Contest from './Contest';
import SiteBreadcrumb from './SiteBreadcrumb';

import toast from 'react-hot-toast';

import api from '../../api/api';

const SingleContest = () => {
    // const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
    const user = JSON.parse(localStorage.getItem('user'));
    const [contest, setContest] = React.useState(null);
    const [team, setTeam] = React.useState(null);
    const [is_payment_done, setIsPaymentDone] = React.useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    React.useEffect(() => {
        if (user && contest===null) {
            api.get('/user/contest/' + id, {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`,
                    "Content-type": "application/json",
                },
                withCredentials: true,
            })
                .then(response => {
                    setContest(response.data.contest);
                    setTeam(response.data.team);
                    setIsPaymentDone(response.data.is_payment_done);
                    console.log(response.data);
                    toast.success('Contest fetched successfully');
                }).catch(error => {
                    toast.error('Could not fetch contest');
                });
        }
        else if(contest===null){
            api.get('/contest/' + id)
                .then(response => {
                    setContest(response.data);
                    toast.success('Contest fetched successfully');
                }).catch(error => {
                    toast.error('Could not fetch contest');
                });
        }
    }, [id, user, contest]);
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='competition'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='contact@vasha.com.bd'
                Location='Science Complex Building, University of Dhaka, Dhaka 1000'
            />
            {
                contest &&
                <SiteBreadcrumb
                    contest={contest}
                />
            }
            <div className='mt-5 m-5'>
                {
                    contest && <Contest contest={contest} team={team} user={user} is_payment_done={is_payment_done}/>
                }
            </div>
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default SingleContest;

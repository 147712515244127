import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';
import postImg1 from '../../../assets/img/blog/post1.jpg';
import postImg2 from '../../../assets/img/blog/post2.jpg';
import logo from '../../../assets/img/logo/csai.png';
import pay_banner from '../../../assets/img/sslcom/pay_banner.png';
import pay_banner2 from '../../../assets/img/sslcom/pay_banner2.jpg';
import pay_banner3 from '../../../assets/img/sslcom/pay_banner3.jpg';

import api from '../../../api/api';
import apis from '../../../api/apis';

const Footer = (props) => {
    const { footerLogo, footerClass, footerTopClass } = props;
    const [blogs, setBlogs] = React.useState([]);
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
    React.useEffect(() => {
        api.get('/blog?page=1')
            .then((response) => {
                setBlogs(response.data.slice(0, Math.min(response.data.length, 2)));
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className={`footer-top ${footerTopClass}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <div className="footer-logo mb-30">
                                <Link to="/" as="/">
                                    <img src={footerLogo ? footerLogo : logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="textwidget pr-60 md-pr-14">
                                <p>CogniStormAI@CAIL, CSEDU</p>
                            </div>
                            {/* <ul className="footer_social">
                                <li>
                                    <a href="#">
                                        <i className="fa fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-pinterest"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-google-plus"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 className="widget-title">Address</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">
                                        Room No: 408, 
                                        Science Complex, 
                                        Department of Computer Science and Engineering, 
                                        University of Dhaka
                                    </div>
                                </li>
                                {/* <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc"><a href="tel:(+880)155-69569">(+880)155-69569</a></div>
                                </li> */}
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc"><a href="mailto:contact@cognistorm.ai">contact@cognistorm.ai</a></div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-50 md-pl-14 footer-widget md-mb-50">
                            <h3 className="widget-title">Our Site</h3>
                            <ul className="site-map">
                                <li><Link to="/course-categories">Tutorials</Link></li>
                                <li><Link to="/blog">Blogs</Link></li>
                                {/* <li><Link to="/course/course-single">Single Course</Link></li> */}
                                {
                                    user ? <li><Link to="/account">Profile</Link></li> :
                                        <li><Link to="/login">Login</Link></li>
                                }
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
                            <h3 className="widget-title">Recent Posts</h3>
                            {/* <div className="recent-post mb-20">
                                <div className="post-img">
                                    <img src={postImg1} alt="blog image" />
                                </div>
                                <div className="post-item">
                                    <div className="post-desc">
                                        <Link to="/blog/single-post-full-width">High school program starting soon 2021</Link>
                                    </div>
                                    <span className="post-date">
                                        <i className="fa fa-calendar-check-o"></i>
                                        October 15, 2020
                                    </span>
                                </div>
                            </div> */}
                            {
                                blogs && blogs.map((blog, index) => (
                                    <div className="recent-post mb-20">
                                        <div key={index} className="post-img">
                                            <img src={apis.cdn_base_url + '/file?filename=' + blog.cover} alt="blog image" />
                                        </div>
                                        <div key={index} className="post-item">
                                            <div className="post-desc">
                                                <Link to={`/blog/single-post-full-width?id=${blog.id}`}>{blog.title}</Link>
                                            </div>
                                            <span className="post-date">
                                                <i className="fa fa-calendar-check-o"></i>
                                                {new Date(blog.created_at).toDateString()}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='container mt-3'>
                        <img src={pay_banner} alt="Payment" />
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;
import React from 'react';
import { Table, Badge } from 'react-bootstrap';

const RankingsTable = ({ rankings }) => {
    const getRankBadge = (rank) => {
        switch(rank) {
            case 1:
                return <Badge variant="warning">🥇 1st</Badge>;
            case 2:
                return <Badge variant="secondary">🥈 2nd</Badge>;
            case 3:
                return <Badge variant="bronze">🥉 3rd</Badge>;
            default:
                return <Badge variant="light">{rank}</Badge>;
        }
    };

    const getRowClass = (rank) => {
        switch(rank) {
            case 1: return 'table-warning';
            case 2: return 'table-secondary';
            case 3: return 'table-bronze';
            default: return '';
        }
    };

    return (
        <Table striped bordered hover responsive>
            <thead className="thead-dark">
                <tr>
                    <th>Rank</th>
                    <th>Team Name</th>
                    <th>Institute</th>
                    <th>Participants</th>
                </tr>
            </thead>
            <tbody>
                {rankings.map((row, index) => (
                    <tr 
                        key={index} 
                        className={getRowClass(row.rank)}
                    >
                        <td>
                            {getRankBadge(row.rank)}
                        </td>
                        <td>{row.team_name}</td>
                        <td>{row.participant_institutes}</td>
                        <td>{row.participant_names}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default RankingsTable;
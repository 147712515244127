import React from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import ScrollToTop from '../../components/Common/ScrollTop';

const RefundPolicyMain = () => {
    const styles = {
        section: {
            padding: '80px 0',
            backgroundColor: '#f8f9fa'
        },
        header: {
            textAlign: 'center',
            marginBottom: '50px'
        },
        mainTitle: {
            fontSize: '2.5rem',
            fontWeight: '700',
            color: '#2c3e50',
            marginBottom: '20px'
        },
        divider: {
            width: '70px',
            height: '4px',
            backgroundColor: '#308C84',
            margin: '0 auto 20px'
        },
        introText: {
            fontSize: '1.1rem',
            color: '#666',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'center',
            lineHeight: '1.8'
        },
        card: {
            border: 'none',
            boxShadow: '0 0 30px rgba(0,0,0,0.05)',
            borderRadius: '15px'
        },
        cardBody: {
            padding: '40px'
        },
        sectionTitle: {
            fontSize: '1.5rem',
            color: '#2c3e50',
            marginBottom: '20px',
            fontWeight: '600',
            paddingBottom: '10px',
            borderBottom: '2px solid #308C8420'
        },
        paragraph: {
            color: '#666',
            lineHeight: '1.8',
            marginBottom: '20px'
        },
        list: {
            listStyle: 'none',
            padding: 0,
            marginBottom: '25px'
        },
        listItem: {
            color: '#666',
            lineHeight: '1.8',
            marginBottom: '15px',
            paddingLeft: '25px',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start'
        },
        bullet: {
            position: 'absolute',
            left: '0',
            color: '#308C84',
            fontWeight: 'bold'
        },
        strongText: {
            color: '#2c3e50',
            fontWeight: '600',
            marginRight: '8px'
        },
        noteBox: {
            backgroundColor: '#fff3cd',
            border: '1px solid #ffeeba',
            borderRadius: '8px',
            padding: '15px 20px',
            marginTop: '30px'
        },
        noteText: {
            color: '#856404',
            margin: 0,
            fontSize: '0.95rem'
        },
        highlight: {
            backgroundColor: '#308C8408',
            padding: '2px 5px',
            borderRadius: '3px',
            color: '#308C84'
        }
    };

    return (
        <React.Fragment>
            <section style={styles.section}>
                <Container>
                    <div style={styles.header}>
                        <h1 style={styles.mainTitle}>Return and Refund Policy</h1>
                        <div style={styles.divider}></div>
                        <p style={styles.introText}>
                            At <a href='https://cognistorm.ai/' style={{ color: '#308C84' }}>Cognistorm.ai</a>, we strive to provide high-quality services, including competition registrations and service subscriptions. This policy outlines our procedures for returns and refunds.
                        </p>
                    </div>

                    <Row className="justify-content-center">
                        <Col lg={10}>
                            <Card style={styles.card}>
                                <Card.Body style={styles.cardBody}>
                                    {/* Introduction */}
                                    <div className="mb-5">
                                        <h2 style={styles.sectionTitle}>1. Introduction</h2>
                                        <p style={styles.paragraph}>
                                            At <a href='https://cognistorm.ai/' style={{ color: '#308C84' }}>Cognistorm.ai</a>, we strive to provide high-quality services, including competition registrations and service subscriptions. We accept payments through <span style={styles.highlight}>SSLCOMMERZ</span>, a secure payment gateway. This Return and Refund Policy outlines the terms under which returns and refunds may be processed.
                                        </p>
                                    </div>

                                    {/* General Policy */}
                                    <div className="mb-5">
                                        <h2 style={styles.sectionTitle}>2. General Policy</h2>
                                        <Alert variant="info" className="mb-4">
                                            Due to the nature of our digital services, all sales are final. We do not offer returns or refunds once a service has been delivered or a competition registration has been completed.
                                        </Alert>
                                        <p style={styles.paragraph}>
                                            We encourage you to review all information about our services before making a purchase.
                                        </p>
                                    </div>

                                    {/* Exceptions */}
                                    <div className="mb-5">
                                        <h2 style={styles.sectionTitle}>3. Exceptions</h2>
                                        <p style={styles.paragraph}>In certain exceptional circumstances, we may consider a refund request if:</p>
                                        <ul style={styles.list}>
                                            {[
                                                { title: 'Service Non-Delivery', content: 'If the service you purchased is not delivered within the specified timeframe due to an error on our part.' },
                                                { title: 'Technical Issues', content: 'If technical problems originating from our end prevent you from accessing or utilizing the service, and we are unable to resolve the issue within a reasonable time.' }
                                            ].map((item, index) => (
                                                <li key={index} style={styles.listItem}>
                                                    <span style={styles.bullet}>•</span>
                                                    <div>
                                                        <span style={styles.strongText}>{item.title}:</span>
                                                        {item.content}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {/* Refund Request Process */}
                                    <div className="mb-5">
                                        <h2 style={styles.sectionTitle}>4. Refund Request Process</h2>
                                        <p style={styles.paragraph}>To request a refund under the exceptions stated above:</p>
                                        <ul style={styles.list}>
                                            {[
                                                { title: 'Contact Us', content: 'Email our support team at [Insert Contact Email] within 7 days of the purchase date, providing your order details and a detailed description of the issue.' },
                                                { title: 'Investigation', content: 'We will review your request and may require additional information to process your claim.' },
                                                { title: 'Resolution', content: 'If your refund is approved, we will process it within 7 to 10 working days. Refunds will be credited to the original payment method.' }
                                            ].map((item, index) => (
                                                <li key={index} style={styles.listItem}>
                                                    <span style={styles.bullet}>•</span>
                                                    <div>
                                                        <span style={styles.strongText}>{item.title}:</span>
                                                        {item.content}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {/* Non-Refundable Situations */}
                                    <div className="mb-5">
                                        <h2 style={styles.sectionTitle}>5. Non-Refundable Situations</h2>
                                        <p style={styles.paragraph}>Refunds will not be issued if:</p>
                                        <ul style={styles.list}>
                                            {[
                                                { title: 'Change of Mind', content: 'You decide not to use the service after purchase.' },
                                                { title: 'Violation of Terms', content: 'You are found to be in violation of our Terms and Conditions.' },
                                                { title: 'Third-Party Issues', content: 'Issues arising from third-party platforms or services that are beyond our control.' }
                                            ].map((item, index) => (
                                                <li key={index} style={styles.listItem}>
                                                    <span style={styles.bullet}>•</span>
                                                    <div>
                                                        <span style={styles.strongText}>{item.title}:</span>
                                                        {item.content}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div style={styles.noteBox}>
                                        <p style={styles.noteText}>
                                            <strong>Note:</strong> This policy is subject to change without prior notice. Please review it periodically for any updates.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    );
}

export default RefundPolicyMain;
import React from 'react';
// import { Link } from 'react-router-dom';

import CourseSidebar from '../tutorial-one/CourseSidebarSection';
// import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';
import CategoriesSingle from '../../components/Categories/CategoriesSingle';

// Course courseImg
// import courseImg1 from '../../assets/img/courses/1.jpg';
// import courseImg2 from '../../assets/img/courses/2.jpg';
// import courseImg3 from '../../assets/img/courses/3.jpg';
// import courseImg4 from '../../assets/img/courses/4.jpg';

// Categories Icon
// import icon1 from '../../assets/img/categories/main-home/icon/1.png';
// import icon2 from '../../assets/img/categories/main-home/icon/2.png';
// import icon3 from '../../assets/img/categories/main-home/icon/3.png';
// import icon4 from '../../assets/img/categories/main-home/icon/4.png';
// import icon5 from '../../assets/img/categories/main-home/icon/5.png';
// import icon6 from '../../assets/img/categories/main-home/icon/6.png';

import api from '../../api/api';
import apis from '../../api/apis';

const CoursePart = (props) => {
    // const [icons, setIcons] = React.useState([icon1, icon2, icon3, icon4, icon5, icon6]);
    const [topics, setTopics] = React.useState(null);
    const [viewList, setViewList] = React.useState(false);


    React.useEffect(() => {
        document.getElementById("rs-popular-course").classList.add('list-view');
        api.get('/tutorial/topic')
            .then((response) => {
                setTopics(response.data);
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const listClassAdd = () => {
        setViewList(false);
        document.getElementById("rs-popular-course").classList.add('list-view');
    };

    const listClassRemove = () => {
        setViewList(true);
        document.getElementById("rs-popular-course").classList.remove('list-view');
    };

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-50" >
            <div className="container">
                <div className="row">

                    <div className="col-lg-8 pr-50 md-pr-14">
                        <div className="course-search-part">
                            <div className="course-view-part">
                                <div className="view-icons">
                                    <button onClick={listClassRemove} className="view-grid mr-10"><i className="fa fa-th-large"></i></button>
                                    <button onClick={listClassAdd} className="view-list"><i className="fa fa-list-ul"></i></button>
                                </div>
                                <div className="view-text">Showing {topics?.length} results</div>
                            </div>
                            {/* <div className="type-form">
                                <form method="post" action="#">
                                    <div className="form-group mb-0">
                                        <div className="custom-select-box">
                                            <select id="timing">
                                                <option>Default</option>
                                                <option>Newest</option>
                                                <option>Old</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                        <div className={viewList ? "row mb-34" : ''}>

                            {
                                topics ?
                                    topics.map((topic, index) => {
                                        return (
                                            <div className={viewList ? "col-lg-4 col-md-6 mb-30 p-2":  "col-lg-4 col-md-6 mb-30 p-2 w-100"} key={index}
                                                style={{
                                                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <CategoriesSingle
                                                    itemClass="categories-items"
                                                    image={apis.cdn_base_url + '/file?filename=' + topic.cover}
                                                    title={topic.name}
                                                    quantity={topic?.tutorial_count + " Tutorials"}
                                                    iconImg={null}
                                                    catLink={`/tutorial?id=${topic.id}`}
                                                    viewList={!viewList}
                                                />
                                            </div>
                                        )
                                    }) :
                                    <div class="text-center">
                                        <div class="spinner-border text-success" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 order-last">
                        <CourseSidebar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoursePart;
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ScrollToTop from '../../components/Common/ScrollTop';

const TermsAndConditionsMain = () => {
	const styles = {
		section: {
			padding: '80px 0',
			backgroundColor: '#f8f9fa'
		},
		header: {
			textAlign: 'center',
			marginBottom: '50px'
		},
		mainTitle: {
			fontSize: '2.5rem',
			fontWeight: '700',
			color: '#2c3e50',
			marginBottom: '20px'
		},
		divider: {
			width: '70px',
			height: '4px',
			backgroundColor: '#308C84',
			margin: '0 auto 20px'
		},
		introText: {
			fontSize: '1.1rem',
			color: '#666',
			maxWidth: '800px',
			margin: '0 auto',
			textAlign: 'center',
			lineHeight: '1.8'
		},
		card: {
			border: 'none',
			boxShadow: '0 0 30px rgba(0,0,0,0.05)',
			borderRadius: '15px'
		},
		cardBody: {
			padding: '40px'
		},
		sectionTitle: {
			fontSize: '1.5rem',
			color: '#2c3e50',
			marginBottom: '20px',
			fontWeight: '600',
			paddingBottom: '10px',
			borderBottom: '2px solid #308C8420'
		},
		subTitle: {
			fontSize: '1.2rem',
			color: '#34495e',
			marginBottom: '15px',
			fontWeight: '500'
		},
		paragraph: {
			color: '#666',
			lineHeight: '1.8',
			marginBottom: '20px'
		},
		list: {
			listStyle: 'none',
			padding: 0,
			marginBottom: '20px'
		},
		listItem: {
			color: '#666',
			lineHeight: '1.8',
			marginBottom: '12px',
			paddingLeft: '25px',
			position: 'relative'
		},
		bullet: {
			position: 'absolute',
			left: '0',
			color: '#308C84',
			fontWeight: 'bold'
		},
		contactBox: {
			backgroundColor: '#f8f9fa',
			padding: '20px',
			borderRadius: '10px',
			marginTop: '20px',
			border: '1px solid #e9ecef'
		}
	};

	return (
		<React.Fragment>
			<section style={styles.section}>
				<Container>
					<div style={styles.header}>
						<h1 style={styles.mainTitle}>Terms and Conditions</h1>
						<div style={styles.divider}></div>
						<p style={styles.introText}>
							These terms and conditions outline the rules and regulations for the use of Cognistorm's Website, located at <a href='https://cognistorm.ai/' style={{ color: '#308C84' }}>https://cognistorm.ai</a>.
						</p>
					</div>

					<Row className="justify-content-center">
						<Col lg={10}>
							<Card style={styles.card}>
								<Card.Body style={styles.cardBody}>
									{/* Acceptance of Terms */}
									<div className="mb-5">
										<h2 style={styles.sectionTitle}>1. Acceptance of Terms</h2>
										<p style={styles.paragraph}>
											By accessing and using the website <a href='https://cognistorm.ai/' style={{ color: '#308C84' }}>https://cognistorm.ai</a> ("Site") and its services ("Services"), you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, please refrain from using the Site and Services.
										</p>
									</div>

									{/* Intellectual Property Rights */}
									<div className="mb-5">
										<h2 style={styles.sectionTitle}>2. Intellectual Property Rights</h2>
										<h3 style={styles.subTitle}>Ownership:</h3>
										<p style={styles.paragraph}>
											All content on the Site, including but not limited to text, images, logos, graphics, software, and trademarks ("Content"), is the property of Cognistorm.ai or its licensors and is protected by intellectual property laws.
										</p>
										<h3 style={styles.subTitle}>Restrictions:</h3>
										<p style={styles.paragraph}>
											You may not reproduce, distribute, modify, or create derivative works of any Content without explicit written permission from Cognistorm.ai.
										</p>
									</div>

									{/* User Conduct */}
									<div className="mb-5">
										<h2 style={styles.sectionTitle}>3. User Conduct</h2>
										<p style={styles.paragraph}>You agree not to:</p>
										<ul style={styles.list}>
											{[
												'Use the Site or Services for any unlawful purposes.',
												'Post or transmit any content that is harmful, offensive, defamatory, or otherwise objectionable.',
												'Attempt to gain unauthorized access to any part of the Site or Services.',
												'Interfere with the proper functioning of the Site or Services, including deploying viruses or other harmful code.',
												'Use automated means, such as bots or scripts, to access or interact with the Site or Services without prior authorization.'
											].map((item, index) => (
												<li key={index} style={styles.listItem}>
													<span style={styles.bullet}>•</span> {item}
												</li>
											))}
										</ul>
									</div>

									{/* Continue with other sections following the same pattern */}

									{/* Contact Information */}
									<div className="mb-4">
										<h2 style={styles.sectionTitle}>10. Contact Information</h2>
										<p style={styles.paragraph}>
											For any questions or concerns regarding these Terms, please contact us at:
										</p>
										<div style={{
											backgroundColor: '#f8f9fa',
											// padding: '20px',
											borderRadius: '10px',
											// marginTop: '20px'
										}}>
											<p style={{ margin: 0, color: '#666' }}>
												<a href="mailto:contact@cognistorm.ai" style={{ color: '#308C84' }}>
													contact@cognistorm.ai<br />
												</a>
											</p>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>

			<ScrollToTop scrollClassName="scrollup orange-color" />
		</React.Fragment>
	);
}

export default TermsAndConditionsMain;